import React, { useState } from 'react';
import {
  Grommet,
  Box,
  Heading,
  Main,
  TextInput,
  Form,
  FormField,
  Button,
  Paragraph,
  Text,
  List,
  Image,
} from 'grommet';
import { getTvShowRating } from './api';

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '1rem',
    },
    colors: {
      brand: '#f3ce13',
    },
  },
};

const Header = ({ children }: { children: JSX.Element }): JSX.Element => (
  <Box
    tag='header'
    align='center'
    justify='center'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
  >
    {children}
  </Box>
);

export type FormOutput = {
  value?: {
    tvShow: string;
  };
};

export type AvgRating = {
  season?: string;
  rating?: string;
  length?: number;
};

function App() {
  const [tvShow, setTvShow] = useState<FormOutput>({});
  const [avgRating, setAvgRating] = useState<AvgRating>({});
  const [avgRatingsBySeason, setAvgRatingsBySeason] = useState<AvgRating[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ value }: FormOutput): Promise<void> => {
    const query = value ? value.tvShow.trim() : null;
    if (!query) return;

    const [totalRating, ratingsBySeason] = await getTvShowRating(query);
    setAvgRating(totalRating || {});
    setAvgRatingsBySeason(ratingsBySeason || []);
    setLoading(false);
  };

  const handleReset = () => {
    setTvShow({});
    setAvgRating({});
    setAvgRatingsBySeason([]);
  };

  return (
    <Grommet theme={theme} full>
      <Header>
        <Heading level={1} size='small'>
          Average TV Episode Rating
        </Heading>
      </Header>

      <Main pad='xlarge' align='center'>
        <Box width='xlarge'>
          <Form
            value={tvShow}
            onChange={setTvShow}
            onReset={handleReset}
            onSubmit={event => {
              setLoading(true);
              const output = (event as unknown) as FormOutput;
              handleSubmit(output);
            }}
          >
            <FormField name='tvShow' htmlFor='tvShow' label='Enter TV Show'>
              <TextInput id='tvShow' name='tvShow' />
            </FormField>
            <Box direction='row' justify='center' gap='medium'>
              <Button type='submit' primary label='Submit' />
              <Button type='reset' label='Reset' />
            </Box>
          </Form>
        </Box>

        {loading ? (
          <Image
            src='https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif'
            margin={{ top: 'xlarge' }}
          />
        ) : null}

        {avgRating.rating && parseInt(avgRating.rating) > 0 ? (
          <Paragraph size='large' margin={{ vertical: 'xlarge' }}>
            <Text weight='bold'>Average Rating ({avgRating.length} eps): </Text>
            {avgRating.rating}
          </Paragraph>
        ) : (
          <Paragraph margin={{ top: 'xlarge' }}>No TV show found</Paragraph>
        )}

        {avgRatingsBySeason.length ? (
          <List
            primaryKey='season'
            secondaryKey='rating'
            data={avgRatingsBySeason.map(item => ({
              season: `Season ${item.season} (${item.length} eps)`,
              rating: item.rating,
            }))}
          />
        ) : null}
      </Main>
    </Grommet>
  );
}

export default App;
