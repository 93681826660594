import axios from 'axios';
import { AvgRating } from './App';

const tmdb = axios.create({
  baseURL: 'https://api.themoviedb.org/3',
  params: { api_key: process.env.REACT_APP_TMDB_KEY },
});

const omdb = axios.create({
  baseURL: 'http://www.omdbapi.com',
  params: { apikey: process.env.REACT_APP_OMDB_KEY, type: 'episode' },
});

type Episode = {
  episode_number: number;
  season_number: number;
  imdbRating: number;
};

type CleanEpisode = {
  episodeNumber: number;
  seasonNumber: number;
};

type ImdbEpisode = {
  imdbRating: number;
  title: string;
  released: string;
  season: number;
  episode: number;
};

export const getAvgImdbRating = (imdbEpisodes: ImdbEpisode[]): string =>
  (
    imdbEpisodes.reduce((sum, { imdbRating }) => sum + imdbRating, 0) /
    imdbEpisodes.length
  ).toFixed(5);

export const getTvShowRating = async (
  tvShowQuery: string
): Promise<[AvgRating, AvgRating[]] | []> => {
  try {
    const {
      data: { results },
    } = await tmdb.get('/search/tv', {
      params: {
        query: tvShowQuery,
      },
    });
    const mostPopularResult = results.reduce(
      (winner: { popularity: number }, contender: { popularity: number }) =>
        contender.popularity > winner.popularity ? contender : winner
    );
    const { id } = mostPopularResult;

    // 2. Get season numbers of TV show using ID
    const {
      data: { seasons },
    } = await tmdb.get(`/tv/${id}`);
    const seasonNumbers = seasons
      .map((season: { season_number: number }) => season.season_number)
      .filter(
        (seasonNumber: number | null) =>
          typeof seasonNumber === 'number' && seasonNumber > 0
      );

    // 3. Get episode numbers from season numbers
    const seasonPromises = seasonNumbers.map((seasonNumber: number) =>
      tmdb.get(`/tv/${id}/season/${seasonNumber}`)
    );
    const seasonResponses = await Promise.all(seasonPromises);
    const episodes = seasonResponses.reduce((acc: any, response: any) => {
      return [
        ...acc,
        ...response.data.episodes.map(
          (episode: Episode): CleanEpisode => ({
            episodeNumber: episode.episode_number,
            seasonNumber: episode.season_number,
          })
        ),
      ];
    }, []) as CleanEpisode[];

    // 4. Get external IDs of episodes
    const externalIdPromises = episodes.map(episode =>
      tmdb.get(
        `/tv/${id}/season/${episode.seasonNumber}/episode/${episode.episodeNumber}/external_ids`
      )
    );
    const externalIdResponses = await Promise.all(externalIdPromises);
    const imdbIds = externalIdResponses
      .map(({ data }: { data: { imdb_id: number } }) => data.imdb_id)
      .filter(Boolean);

    // 5. Get IMDb ratings from IMDb IDs
    const imdbEpisodesPromises = imdbIds.map(id =>
      omdb.get('/', { params: { i: id } })
    );

    const imdbEpisodesResponses = await Promise.all(imdbEpisodesPromises);
    const imdbEpisodes = imdbEpisodesResponses
      .map(
        ({ data }): ImdbEpisode => ({
          imdbRating: parseFloat(data.imdbRating),
          title: data.Title,
          released: data.Released,
          season: parseInt(data.Season),
          episode: parseInt(data.Episode),
        })
      )
      .filter(episode => !Number.isNaN(episode.season) && episode.season > 0);

    // 6. Print out average IMDb rating
    const avgImdbRating = getAvgImdbRating(imdbEpisodes);

    // 7. Print out average IMDb rating per season
    const imdbEpisodesBySeason = imdbEpisodes.reduce((acc, cur) => {
      // @ts-ignore
      if (!acc[cur.season]) {
        // @ts-ignore
        acc[cur.season] = [];
      }
      // @ts-ignore
      acc[cur.season].push(cur);
      return acc;
    }, {});

    const avgImdbRatingsBySeason = Object.keys(imdbEpisodesBySeason)
      .sort()
      .map(
        (season): AvgRating => {
          // @ts-ignore
          const episodesForSeason = imdbEpisodesBySeason[season];
          const avgSeasonRating = getAvgImdbRating(episodesForSeason);
          return {
            season,
            length: episodesForSeason.length,
            rating: avgSeasonRating,
          };
        }
      );

    return [
      { length: imdbEpisodes.length, rating: avgImdbRating },
      avgImdbRatingsBySeason,
    ];
  } catch (error) {
    console.log(error);
    return [];
  }
};
